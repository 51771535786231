import * as React from 'react';
import {
    Link
} from 'gatsby';
import DefaultPage from '../../components/layouts/DefaultPage';
import {
    connect
} from 'react-redux';
import {
    useEffect
} from 'react';

// markup
const VerifiedPage = ({
    setActiveLoginTab,
}) => {
    useEffect(
        () => {
            setActiveLoginTab({
                tabId: 'login',
            });
        },
        []
    );
    return (
        <DefaultPage>
            <p>Ihre E-Mail Adresse wurde erfolgreich verifiziert. Bitte <Link to="/user/login-register">loggen Sie sich ein.</Link></p>
        </DefaultPage>
    );
};

const mapStateToProps = ({}) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveLoginTab: ({
            tabId,
        }) => {
            return dispatch({
                type: 'SET_ACTIVE_LOGIN_TAB',
                payload: {
                    activeLoginTab: tabId,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifiedPage);
